import React from 'react';
import styled from 'styled-components';

import useDetailsExplorer from '../../hooks/useDetailsExplorer';
import CollapsibleTextField from '../../components/CollapsibleTextField';

const Header = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export default function Description() {
  const {
    listing: { description, make, manufacturer, model, year },
  } = useDetailsExplorer();

  return (
    <div className="mb3">
      <Header data-cy="ldp-description-header">
        {year} {manufacturer} - {make} {model}
      </Header>
      <div className="description-body" data-cy="ldp-description-body">
        {description ? (
          <CollapsibleTextField
            content={description}
            type="description"
            length={400}
            expandLabel="Read More"
            collapseLabel="Show Less"
            track
            label="Description"
          />
        ) : (
          <div className="coming-soon" data-cy="ldp-coming-soon">
            Coming soon
          </div>
        )}
      </div>
    </div>
  );
}
