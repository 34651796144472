import React from 'react';
import { Flex } from 'core/ui';

function isNullOrUndefined(x) {
  return x === null || x === undefined;
}

export function responseTimeText(responseTimeMinutes) {
  if (isNullOrUndefined(responseTimeMinutes)) {
    return 'within a day';
  }

  const responseTimeHours = responseTimeMinutes / 60;

  if (responseTimeHours <= 1) return 'within 1 hour';
  if (responseTimeHours <= 4) return 'within 4 hours';
  if (responseTimeHours <= 8) return 'within 8 hours';
  if (responseTimeHours <= 24) return 'within a day';

  return 'in more than a day';
}

export default function ResponseData({ responseRate, responseTimeMinutes, ...restProps }) {
  return (
    <Flex flexDirection="column" {...restProps}>
      <strong>Typically responds {responseTimeText(responseTimeMinutes)}</strong>
      {!isNullOrUndefined(responseRate) && <div>{responseRate}% response rate</div>}
    </Flex>
  );
}
