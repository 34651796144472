import React from 'react';

import DefaultTowablePhoto from 'images/default-towable-avatar.png';
import DefaultDrivablePhoto from 'images/default-drivable-avatar.png';
import UserPhoto from 'components/UserPhoto';

export default function OwnerAvatar({ ownerPhoto, ownerName, drivableRv, size='56' }) {
  return (
    <div className="flex justify-center">
      {ownerPhoto ? (
        <UserPhoto
          fileName={ownerPhoto}
          options={{
            alt: ownerName || 'Owner',
            width: size,
            height: size,
          }}
        />
      ) : (
        <img
          src={drivableRv ? DefaultDrivablePhoto : DefaultTowablePhoto}
          alt={ownerName || 'Owner'}
          height={`${size}px`}
          width={`${size}px`}
          loading="lazy"
        />
      )}
    </div>
  );
}
