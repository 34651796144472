import React from 'react';
import styled from 'styled-components';
import { Button } from '@rvshare/core-ui';

import withHitchTheme from 'core/withHitchTheme';
import { useQuoteBuilderContext } from '../../hooks/useQuoteBuilderContext';

const SearchButton = styled(Button)`
  font-size: 16px;
  height: 40px;
`;

const Centered = styled.div`
  width: 100%;
  text-align: center;
  padding: 1.5rem;

  > * { margin: auto; }
  > div { margin-bottom: 1.5rem; }
  > form > button { margin-top: 0.5rem; }
`;


function Unavailable({ desktop }) {
  const { classType, lat, lng } = useQuoteBuilderContext()

  return (
    <Centered>
      <h2>Currently Unavailable</h2>
      {desktop && (
      <div>Let&apos;s find you something else</div>
        )}
      <form action="/rv-rental" method="get">
        <input type="hidden" name="rv_class" value={classType} />
        <input type="hidden" name="lat" value={lat} />
        <input type="hidden" name="lng" value={lng} />
        <SearchButton type="submit">
          View Similar RV Rentals
        </SearchButton>
      </form>
    </Centered>
  )
}

export default withHitchTheme(Unavailable);
