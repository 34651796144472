import React from 'react';
import styled from 'styled-components';
import { subDays, startOfToday } from 'date-fns';
import { Container, Flex } from '@rvshare/core-ui';

import withHitchTheme from 'core/withHitchTheme';
import { Link } from 'core/ui';
import { isDrivable } from 'core/helpers/RV';

import SafeTracking from 'tracking/SafeTracking';
import OwnerAvatar from './OwnerAvatar';
import { HitchContactOwnerButton } from '../../components/ContactOwner/ContactOwnerButton';
import ResponseData from '../../components/ResponseData';
import CollapsibleTextField from '../../components/CollapsibleTextField';
import useDetailsExplorer from '../../hooks/useDetailsExplorer';

const AboutOwnerContainer = styled(Container)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};
`;

const HostedBy = styled.h3`
  font-size: 1.25rem;
  margin: 0;
`;

const OwnerAboutText = styled(Flex)`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const ContactOwnerButton = styled(HitchContactOwnerButton)`
  margin-right: 1rem;
  padding: 0 1.125rem;
`;

const Dot = styled.div`
  color: ${({ theme }) => theme.colors.grey400};
  padding: 0 5px;
`;

const FleetLink = styled(Link)`
  color: ${({ theme }) => theme.colors.bodyColor};
  text-decoration: underline !important;

  &:hover,
  &:visited {
    color: ${({ theme }) => theme.colors.bodyColor};
  }
`;

const ResponseDataStyled = styled(ResponseData)`
  font-size: 0.875rem;
`;

function AboutOwner() {
  const {
    owner: {
      id: ownerId,
      name: ownerName,
      photo: ownerPhoto,
      about: aboutOwnerText,
      user_age: ageInDays,
      email: ownerEmail,
      fleet_owner: fleetOwner,
      ownerMetrics,
    },
    listing: { classType, searchBlocked },
  } = useDetailsExplorer();
  const drivableRv = isDrivable({ name: classType });

  const trackFleetLinkClick = () => {
    SafeTracking.track('Fleet Page link click', { owner_id: ownerId, owner_email: ownerEmail });
  };

  return (
    <AboutOwnerContainer flexDirection="column" pt="xl" pb="md" mb="xl" data-testid="about-owner">
      <Flex mb="sm" alignItems="center">
        <Flex mr="sm">
          <OwnerAvatar ownerPhoto={ownerPhoto} ownerName={ownerName} drivableRv={drivableRv} />
        </Flex>
        <Flex flexDirection="column">
          {ownerName && (
            <HostedBy data-testid="about-owner-hosted-by">Hosted by {ownerName}</HostedBy>
          )}
          <Flex flexDirection="row" fontSize="sm" m="0" data-cy="about-owner-member-since">
            Member since {subDays(new Date(startOfToday()), ageInDays).getFullYear()}
            {fleetOwner && (
              <>
                <Dot>•</Dot>
                <FleetLink
                  href={`/fleet/${ownerId}`}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  onClick={trackFleetLinkClick}
                  data-testid="about-fleet-link"
                >
                  View all rentals
                </FleetLink>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      {aboutOwnerText && (
        <OwnerAboutText>
          <CollapsibleTextField
            length={400}
            content={aboutOwnerText}
            expandLabel="Read More"
            collapseLabel="Show Less"
            track
            label="About owner"
            data-cy="about-owner-text"
          />
        </OwnerAboutText>
      )}
      <Flex data-cy="about-owner-contact-row" alignItems="center" mt="sm">
        {!searchBlocked && (
          <>
            <ContactOwnerButton
              trackingValues={{
                linkLocation: 'Contact Owner',
              }}
            />
            <ResponseDataStyled
              responseRate={ownerMetrics?.responseRate}
              responseTimeMinutes={ownerMetrics?.responseTimeMinutes}
            />
          </>
        )}
      </Flex>
    </AboutOwnerContainer>
  );
}

export default withHitchTheme(AboutOwner);
