import React, { useRef, useState } from 'react';
import cx from 'classnames';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, A11y, Lazy, Keyboard } from 'swiper';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import { Image } from 'core/ui';
import { MaybeBlankDataSrcImage } from 'components/MaybeBlankImage';
import useIsomorphicLayoutEffect from 'core/hooks/useIsomorphicLayoutEffect';
import { deferred } from 'core/helpers/defer';
import DesktopOverlay from './DesktopOverlay';
import MobileOverlay from './MobileOverlay';

const SwiperContainer = styled(Swiper)`
  overflow: hidden;
  width: 100%;
  height: auto;
  max-height: 469px;
  aspect-ratio: 731 / 469;

  ${({ theme }) => theme.xlarge`
    width: 50%;
    margin-left: calc(1 / 12 * 100vw);
    overflow: visible;
  `}

  .carousel-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      display: block;
      object-fit: cover;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;

    &:after {
      content: '';
    }

    svg {
      color: white;
      transition: 0.2s ease-in-out;
      text-shadow: 5px 5px 10px black;

      &:hover,
      &:focus {
        color: #ccc;
        text-shadow: 3px 3px 5px black;
      }
    }
  }
`;

export default function SwiperCarousel(props) {
  const swiperRef = useRef(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  useIsomorphicLayoutEffect(() => setIsInitialRender(false), []);
  const [currentIndex, setCurrentIndex] = useState(1);

  // we honestly don't care too much about the classes on the slides getting updated so let's have it run async
  // this improves responsiveness on the carousel
  const deferSwiperEvents = swiper => {
    if (swiper.updateSlidesClasses) {
      // eslint-disable-next-line no-param-reassign
      swiper.updateSlidesClasses = deferred(swiper.updateSlidesClasses.bind(swiper));
    }
  };

  const openFullScreen = () => window.open(`${window.location.href}/image-gallery`, '_self');

  const overlayProps = {
    onNextClick: () => swiperRef.current?.swiper.slideNext(),
    onPrevClick: () => swiperRef.current?.swiper.slidePrev(),
    onOpenFullScreen: isInitialRender ? undefined : openFullScreen,
    currentIndex,
  };

  return (
    <>
      <DesktopOverlay {...overlayProps} {...props} />
      <MobileOverlay {...overlayProps} {...props} />
      <SwiperContainer
        ref={swiperRef}
        modules={[Navigation, A11y, Lazy, Keyboard]}
        loop
        navigation={{
          nextEl: '.carousel-next-btn',
          prevEl: '.carousel-prev-btn',
        }}
        a11y={{
          prevSlideMessage: 'Previous slide',
          nextSlideMessage: 'Next slide',
        }}
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: 2,
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
        preloadImages={false}
        preventClicks={false}
        spaceBetween={10}
        // setTimeout to maximize responsiveness
        onRealIndexChange={swiper => setTimeout(() => setCurrentIndex(swiper.realIndex + 1), 0)}
        onTap={openFullScreen}
        onSwiper={deferSwiperEvents}
      >
        {props.images.map(({ caption, urls }, i) => {
          const isFirstImage = i === 0;
          const shouldLazyLoad = !isFirstImage;
          const srcSet = `${urls[0]}, ${urls[1] || urls[0]} 2x`;
          const slideKey = urls[0] + i;

          return (
            // initial (ssr) render has the first slide one position to the right for some reason, so we need to adjust it
            <SwiperSlide
              key={slideKey}
              className="carousel-cell"
              style={{ left: i === 0 && isInitialRender ? '-100%' : 0 }}
            >
              <MaybeBlankDataSrcImage
                className={cx('carousel-cell-image', { 'swiper-lazy': shouldLazyLoad })}
                alt={caption || 'RV Photo'}
                width={731}
                height={469}
                data-src={urls[0]}
                data-srcset={srcSet}
                initialSrc={isFirstImage ? urls[0] : undefined}
                initialSrcSet={isFirstImage ? srcSet : undefined}
                fetchpriority={isFirstImage ? 'high' : 'auto'}
                tag={Image}
              />
            </SwiperSlide>
          );
        })}
      </SwiperContainer>
    </>
  );
}
