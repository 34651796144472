import React from 'react';
import styled from 'styled-components';

import withHitchTheme from 'core/withHitchTheme';

const AlternatingBackgroundsContainer = styled.div`
  > div:nth-child(odd) {
    background-color: ${props => props.theme.colors.grey100};
  }
`;

function AlternatingBackgrounds({ children }) {
  return (
    <AlternatingBackgroundsContainer data-testid="alternating-backgrounds-container">
      {children}
    </AlternatingBackgroundsContainer>
  );
}

export default withHitchTheme(AlternatingBackgrounds);
