/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { withOptimizelyInstance } from 'core/state/Optimizely';
import { withTravelPreferences } from 'core/state/Globals';
import { useDevice } from 'core/hooks/useDevice';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { trackDetailsView } from 'tracking/rvs/TrackDetailsView';

import useRvImagePlaceholders from 'core/hooks/useRvImagePlaceholders';
import { DetailsExplorerContext } from '../hooks/useDetailsExplorer';
import QuoteBuilder from './QuoteBuilder';
import ImageHero from './ImageHero';
import JumpNavBar from './JumpNavBar';
import AlternatingBackgrounds from './AlternatingBackgrounds';
import DescriptionSection from './Description';
import AmenitiesSection from './Amenities';
import RulesPoliciesSection from './RulesPolicies';
import RatesAvailabilitySection from './RatesAvailability';
import DeliveryOptions from './DeliveryOptions';
import RvLocationSection from './RvLocation';
import ReportListingSection from './ReportListing';
import RVTipsSection from './RVTips';
import ReviewsSection from './Reviews';
import SeoLinksSection from './SeoLinks';
import FAQSection from './FAQ';
import Breadcrumbs from './BreadCrumbs';
import useDetailsContext from '../hooks/useDetailsContext';

const SafeQuoteBuilder = withErrorBoundary(QuoteBuilder, 'warning');
const SafeImageHero = withErrorBoundary(ImageHero, 'warning');
const SafeJumpNavBar = withErrorBoundary(JumpNavBar, 'warning');
const SafeDescriptionSection = withErrorBoundary(DescriptionSection, 'warning');
const SafeAmenitiesSection = withErrorBoundary(AmenitiesSection, 'warning');
const SafeRulesPoliciesSection = withErrorBoundary(RulesPoliciesSection, 'warning');
const SafeRatesAvailabilitySection = withErrorBoundary(RatesAvailabilitySection, 'warning');
const SafeDeliveryOptionsSection = withErrorBoundary(DeliveryOptions, 'warning');
const SafeRvLocationSection = withErrorBoundary(RvLocationSection, 'warning');
const SafeReportListingSection = withErrorBoundary(ReportListingSection, 'warning');
const SafeRVTipsSection = withErrorBoundary(RVTipsSection, 'warning');
const SafeReviewsSection = withErrorBoundary(ReviewsSection, 'warning');
const SafeSeoLinksSection = withErrorBoundary(SeoLinksSection, 'warning');
const SafeFAQSection = withErrorBoundary(FAQSection, 'warning');
const SafeBreadcrumbs = withErrorBoundary(Breadcrumbs, 'warning');

const Main = styled.div`
  position: relative;
`;

function DetailsExplorer(props) {
  const { listing, optimizelyData } = props;

  // Adds placeholder images in local dev
  const { rvImages: updatedRvImages } = useRvImagePlaceholders(listing.images);
  const { isMobile } = useDevice();
  const { decision } = optimizelyData;

  const context = useDetailsContext(props, updatedRvImages);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackDetailsView({ ...props, decision }), []);

  return (
    <DetailsExplorerContext.Provider value={context}>
      <Main className="details-container">
        <SafeImageHero />
        <SafeQuoteBuilder />
        {!isMobile && <SafeJumpNavBar />}
        <SafeDescriptionSection />
        <AlternatingBackgrounds>
          <SafeAmenitiesSection />
          <SafeReviewsSection />
          <SafeRulesPoliciesSection />
          <SafeRatesAvailabilitySection />
          <SafeDeliveryOptionsSection />
          <SafeRvLocationSection />
          <SafeReportListingSection />
          <SafeRVTipsSection />
          <SafeBreadcrumbs />
        </AlternatingBackgrounds>
      </Main>
      <SafeSeoLinksSection />
      <SafeFAQSection />
    </DetailsExplorerContext.Provider>
  );
}

export default withOptimizelyInstance(withTravelPreferences(DetailsExplorer));
