import React from 'react';
import { Flex, Icon } from '@rvshare/core-ui';
import styled from 'styled-components';

import withHitchTheme from 'core/withHitchTheme';
import useDetailsExplorer from '../../hooks/useDetailsExplorer';

const Wrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};
  justify-content: space-between;

  ${({ theme }) => theme.media.medium`
    justify-content: flex-start;
  `}
`;

const Item = styled(Flex)`
  margin-right: 1.5rem;

  :last-child {
    margin-right: 0;
  }

  svg {
    margin-right: 0.25rem;
  }
`;

function Summary() {
  const {
    listing: { sleeps, length },
    delivery: { locations, ranges },
    services: { offersDelivery },
  } = useDetailsExplorer();

  const hasLocations = !!locations?.length;
  const hasRanges = !!Object.keys(ranges || {}).length;
  const showOffersDelivery = offersDelivery || hasLocations || hasRanges;

  return (
    <Wrapper pt="md" pb="md" mb="xl">
      <Item data-cy="ldp-summary-sleeps">
        <Icon icon="bed" size={18} fill="primary" /> Sleeps {sleeps}
      </Item>
      <Item data-cy="ldp-summary-length">
        <Icon icon="length" size={18} fill="primary" />
        {Math.trunc(length)} ft long
      </Item>
      <Item data-cy="ldp-summary-delivery">
        <Icon icon={showOffersDelivery ? 'route' : 'key'} size={18} fill="primary" />
        {showOffersDelivery ? 'Offers Delivery' : 'Pick Up Only'}
      </Item>
    </Wrapper>
  );
}

export default withHitchTheme(Summary);
